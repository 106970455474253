import * as React from "react";
import Layout from "../../components/layout";
import { graphql, Link } from "gatsby";

// gatsby-plugin-mdx automatically slugifies the mdx name

// https://refactoring.guru/
// https://youtu.be/tv-_1er1mWI

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="My Blog Posts" flex={true}>
      <div className="flex flex-wrap ">
        <section className="body-font mx-auto overflow-hidden">
          <div className="flex flex-col px-3 lg:max-w-4xl py-10 mx-auto">
            {data.allMdx.group.map((item, itemIdx) => (
              <div key={itemIdx} className="py-5 ">
                <h1 className="text-xl  text-cyan-500 font-normal">
                  {item.fieldValue} {` (${item.totalCount})`}
                </h1>
                <div className="divide-y divide-cyan-800">
                  {item.edges.map((edge) => (
                    <div className="p-5" key={edge.node.id}>
                      <Link to={`/blog/${edge.node.slug}`} key={edge.node.id}>
                        <p className="text-sm float-right font-medium leading-6 text-gray-600 ">
                          {edge.node.frontmatter.date}
                        </p>
                        <h2 className="text-lg font-medium leading-8 tracking-tight text-gray-400">
                          {edge.node.frontmatter.title}
                        </h2>
                        <div className="mt-5">{edge.node.frontmatter.desc}</div>
                        <div className="text-base font-medium mt-5 leading-6">
                          <div className="flex flex-row flex-wrap">
                            {edge.node.frontmatter.tags.map((tag, i) => (
                              <span
                                key={edge.node.id}
                                className="py-1 px-3 mx-1 rounded bg-cyan-200 mb-3 text-cyan-900 text-xs font-medium tracking-widest"
                              >
                                {tag}
                              </span>
                            ))}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};
export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            frontmatter {
              title
              category
              desc
              date(formatString: "MMMM DD, YYYY")
              tags
            }
            slug
            id
          }
        }
      }
    }
  }
`;
export default BlogPage;
